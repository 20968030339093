import "./Logo.scss";
import React from "react";

function template() {
  return (
    <div className="logo">
      <h1>Logo</h1>
    </div>
  );
};

export default template;
