import React    from "react";
import template from "./Home.jsx";
import Social from "../Social";
import Player from "../Player";

class Home extends React.Component {
  render() {
    return (
    	<div className="home-container">
    		<Social />
    		<Player />
    	</div>
	);
  }
}

export default Home;
