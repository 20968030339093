import React    from "react";
import template from "./Logo.jsx";

class Logo extends React.Component {
  render() {
    return (
    	<div class="logo-container"></div>
	);
  }
}

export default Logo;
