import "./Media.scss";
import React from "react";

function template() {
  return (
    <div className="media-container">
      <h1>Media</h1>
    </div>
  );
};

export default template;
