import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from "./Home/Home";
import Epk from "./Epk/Epk";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/epk">
            <Epk />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}