import "./Player.scss";
import React from "react";

function template() {
  return (
    <div className="player">
      <h1>Player</h1>
    </div>
  );
};

export default template;
