import React    from "react";
import template from "./Contacts.jsx";

class Contacts extends React.Component {
  render() {
    return (
    	<div className="contacts-container">
    		<div className="contacts-content">
	    		<a title="Mail us" class="contacts-link" href="mailto:thegodgivenband@gmail.com">thegodgivenband@gmail.com</a>
    		</div>
	    </div>
    );
  }
}

export default Contacts;
