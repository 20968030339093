import React    from "react";
import template from "./Discography.jsx";

class Discography extends React.Component {
  render() {
    return (
    	<div className="discography-container">
    		<h1>Releases</h1>
    		<div className="discography-content">
	    		<div className="discography-row">
		    		<h2>Not that Godly (Album 2021)</h2>
		    		<p>Ghostship Recordings / Gabor Toth / Balint Csongor / JFT / MuD</p>
	    		</div>
	    		<div className="discography-row">
		    		<h2>Strayght (Single 2018)</h2>
		    		<p>Ghostship Recordings / Gabor Toth</p>
	    		</div>
	    		<div className="discography-row">
		    		<h2>Self is Team (Single 2018)</h2>
		    		<p>Ghostship Recordings / Gabor Toth</p>
	    		</div>
	    		<div className="discography-row">
		    		<h2>Out of Box (EP 2016)</h2>
		    		<p>MMP Studio / Istvan Szilagyi / Moskito / Szeg</p>
	    		</div>
	    		<div className="discography-row">
		    		<h2>Walk on the Rope (EP 2014)</h2>
		    		<p>Black Hole Sound Studio / Gabor Vari / Balint Csongor</p>
	    		</div>
	    		<div className="discography-row">
		    		<h2>Low (EP 2012)</h2>
		    		<p>AMP Studio / Attila Pallagi</p>
	    		</div>
    		</div>
	    </div>
    );
  }
}

export default Discography;
