import "./Tour.scss";
import React from "react";

function template() {
  return (
    <div className="tour-container">
      <h1>Tour</h1>
    </div>
  );
};

export default template;
