import React    from "react";
import template from "./About.jsx";

class About extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            active: false
        };
        this.toggleDesc = this.toggleDesc.bind(this);
    }
    toggleDesc() {
    	this.setState(state => ({
	      active: !state.active
	    }));
    };
    
  render() {
    return (<div onClick={this.toggleDesc} className={`about-container ${this.state.active ? 'full' : ''}`}>
    	<div>
    	<p>Grown from the Hungarian hardcore-metal scene back in the early 2010's, Godgiven has now become a self-contained musical phenomenon, not fitting in any kind of trends or traditions.</p>
<p>No, it's not a christian and not even an atheist band! As neither specifically adopts any political ideologies, but rather the advocate for the freethinker that has its own rough opinion on the world. Thus the name Godgiven has nothing to do with religion, but refers to raw power, to the unconstrained instinct, the unbridled talent.</p>
<p>The content requires a proper shape, which in this case is an indecent, honest and ballsy music that unifies the heaviness of groove metal, the dynamism of punk and hardcore or the raw sound of the early nu-metal etc. This production cannot be defined by any musical genre but by an attitude by which all the diversified influences join.</p>
<p>This stuff ain't about comfort or perfectness. This is the world's derivative from the aspect of a badass...</p></div></div>);
  }
}

export default About;
