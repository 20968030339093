import React    from "react";
import template from "./Epk.jsx";

import About from "../About";
import Logo from "../Logo";
import Social from "../Social";
import Discography from "../Discography";
import Tour from "../Tour";
import Media from "../Media";
import Contacts from "../Contacts";
/*import Shows from "../Shows";
import Music from "../Music";
import Video from "../Video";*/

/*class Epk extends React.Component {
  render() {
    return (
    	<div>
	    <About />
	    <Shows />
	    <Music />
	    <Video />
	    </div>
    );
  }
}*/

class Epk extends React.Component {
  render() {
    return (
    	<div className="epk-container">
    		<div className="epk-row header-row">
		    	<Logo />
		    	<Social align="column" />
			    <About />
		    </div>
		    <Social align="row" />
		    <Contacts />
		    <div className="epk-row">
		    	<Discography />
		    </div>
		    <div className="epk-row">
		    	<Tour />
		    </div>
		    <div className="epk-row">
		    	<Media />
		    </div>
	    </div>
    );
  }
}

export default Epk;
