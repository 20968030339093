import React    from "react";
import template from "./Tour.jsx";

class Tour extends React.Component {
  render() {
    return (
    	<div className="tour-container">
    		<h1>Tour</h1>
    		<div className="tour-content">
    			<div className="tour-row">
		    		<h2>Last Fest 2017</h2>
		    		<p>Castelfidardo / MuD / Raw Power / Impact</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>VHK Festival 2014</h2>
		    		<p>Budapest / VHK / Isten Hata Mogott / Dereng / Kretens</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Euro Tour 2017</h2>
		    		<p>South-West Europe / Raw Power / Impact / MuD / The Vega Brothers</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Beastern Europe Tour 2017</h2>
		    		<p>East Europe / JFT</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Moshing Rabbits Festival 2017</h2>
		    		<p>Budapest / Durer Kert / Cadaveres / Don Gatto / Stong Deformity</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Banat Fest 2016</h2>
		    		<p>Banatsko Novo Selo / Orest / Demist</p>
	    		</div>
    			<div className="tour-row">
		    		<h2>Barba Negra 2019</h2>
		    		<p>Budapest / Tumor Called Marla</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>RockPart 2014</h2>
		    		<p>Balatonszemes / Behemoth / Ensiferum</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Balkan Tours 2015 / 2016</h2>
		    		<p>JFT / Bright Sight / Lowlife / Badcast</p>
	    		</div>
	    		<div className="tour-row">
		    		<h2>Hungarian Tours 2012-</h2>
		    		<p>Death by Stereo / Last Hope / Blind Myself / Leander Rising / Apey and the Pea / Vibratacore / Prosectura / Szeg</p>
	    		</div>
    		</div>
	    </div>
	);
  }
}

export default Tour;
