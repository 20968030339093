import React    from "react";
import template from "./Media.jsx";

class Media extends React.Component {
  render() {
    return (
    	<div className="media-container">
    		<h1>Media</h1>
    		<div className="media-content">
	    		<div className="media-row">
		    		<p>NuSkull</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Rock FM</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Tilos Radio</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Langolo Gitarok</p>
	    		</div>
	    		<div className="media-row">
		    		<p>The Metal Pit</p>
	    		</div>
	    		<div className="media-row">
		    		<p>HammerWorld</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Metal Noise</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Rock Rage Radio</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Honest Brutality</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Shock Magazin</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Femforgacs</p>
	    		</div>
	    		<div className="media-row">
		    		<p>Rockstation</p>
	    		</div>
    		</div>
	    </div>
	);
  }
}

export default Media;
