import React    from "react";
import template from "./Social.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebook, faYoutube, faSpotify, faInstagram, faItunes } from '@fortawesome/free-brands-svg-icons';

class Home extends React.Component {
  render() {
    return (
		<div className={`social-links-container social-links-container-${ this.props.align }`}>
			<a title="Godgiven Facebook" class="social-link" href="https://www.facebook.com/godgivenband" alt="Godgiven Facebook" target="_blank">
	    		<FontAwesomeIcon icon={faFacebook} />
	    	</a>
	    	<a title="Godgiven Spotify" class="social-link" href="https://open.spotify.com/artist/6OjveAxQIYUN5Grx6YCCnq" alt="Godgiven Spotify" target="_blank">
	    		<FontAwesomeIcon icon={faSpotify} />
	    	</a>
	    	<a title="Godgiven Youtube" class="social-link" href="https://www.youtube.com/user/godgivenband" alt="Godgiven Youtube" target="_blank">
	    		<FontAwesomeIcon icon={faYoutube} />
	    	</a>
	    	<a title="Godgiven iTunes" class="social-link" href="https://music.apple.com/ng/artist/godgiven/1347516574" alt="Godgiven iTunes" target="_blank">
	    		<FontAwesomeIcon icon={faItunes} />
	    	</a>
	    	<a title="Godgiven Instagram" class="social-link" href="http://instagram.com/godgivenband" alt="Godgiven Instagram" target="_blank">
	    		<FontAwesomeIcon icon={faInstagram} />
	    	</a>
		</div>
	);
  }
}

export default Home;
