import React    from "react";
import template from "./Player.jsx";

class Player extends React.Component {
  render() {
    return (
    	<div className="player-container">
    		<iframe src="https://open.spotify.com/embed/album/1N9NPC5IynwQFN3G6hDb10" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    	</div>
	);
  }
}

export default Player;
